import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import '../styles/footer.scss';

import TextLogo from '../images/textLogo.svg';
import iconIG from '../images/icon-IG.svg';
import iconTwitter from '../images/icon-Twitter.svg';
import iconEmail from '../images/icon-Email.svg';

class Footer extends React.Component {
  render() {
    const { terseFooter, colorfulSection } = this.props;
    // const year = new Date(document.lastModified).getFullYear();
    return (
      <footer className="footer">
        { terseFooter ?
          <div className="footer-terse">
            <div>
              <div>&copy; <a href="https://www.blockstep.net">Blockstep LLC</a> <br /><span>&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;</span> Humbly is a registered trademark</div>
              <div>
                <NavLink to="/contact">Contact</NavLink><span>&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;</span>
                <NavLink to="/privacy">Privacy Policy</NavLink><span>&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;</span>
                <NavLink to="/terms">Terms and Conditions</NavLink>
              </div>
            </div>
          </div>
        :
          <div className={`footer-full ${colorfulSection ? 'colorful-section' : null}`}>
            <div className="inner">
              <div className="footer-full-logo">
                <img src={TextLogo} alt="Humbly text logo" />
              </div>
              <div className="footer-full-socials">
                <a href="https://www.instagram.com/humblyFM" className="footer-full-socials-button" aria-label="Humbly Instagram @humblyFM">
                  <img src={iconIG} alt="Instagram icon" />
                  <span>@humblyFM</span>
                </a>

                <a href="https://www.twitter.com/humblyFM<" className="footer-full-socials-button" aria-label="Humbly Twitter @humblyFM">
                  <img src={iconTwitter} alt="Twitter icon" />
                  <span>@humblyFM</span>
                </a>

                <a href="mailto:hello@humbly.fm" className="footer-full-socials-button" aria-label="contact Humbly hello@humbly.fm">
                  <img src={iconEmail} alt="Email icon" />
                  <span>hello@humbly.fm</span>
                </a>
              </div>
              <div className="footer-full-legal">
                <div>&copy; <a href="https://www.blockstep.net">Blockstep LLC</a> <br /><span>&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;</span> Humbly is a registered trademark</div>
                <div>
                  <NavLink to="/contact">Contact</NavLink><span>&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;</span>
                  <NavLink to="/privacy">Privacy Policy</NavLink><span>&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;</span>
                  <NavLink to="/terms">Terms and Conditions</NavLink>
                </div>
              </div>
            </div>
          </div>
        }
      </footer>
    )
  }
}

Footer.defaultProps = {
  terseFooter: false
}

Footer.propTypes = {
  terseFooter: PropTypes.bool
}

export default Footer;