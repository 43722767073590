import React from 'react';
import { PropTypes } from 'prop-types';

import LandingPoster from '../video/landing-poster.jpg';
import LandingDemoMP4 from '../video/landing-demo.mp4';
import LandingDemoOGG from '../video/landing-demo.ogg';

import AdvertisePoster from '../video/ad-poster.jpg';
import AdvertiseDemoMP4 from '../video/ad-demo.mp4';
import AdvertiseDemoOGG from '../video/ad-demo.ogg';

class Device extends React.Component {
  constructor() {
    super();
    this.deviceRef = React.createRef();
    this.state = {
      poster: null,
      sourceMP4: null,
      sourceOGG: null,
      isSmall: null
    }
  }

  setSize() {
    if (this.deviceRef.current) {
      const device = this.deviceRef.current;
      const deviceWidth = device.offsetWidth;
      const ratios = {
        height: 2.1,
        corner: .137
      }
      device.style.height = (deviceWidth * ratios.height) + 'px';
      device.style.borderRadius = (deviceWidth * ratios.corner) + 'px';
    }
  }

  componentDidMount() {
    const windowWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      if (windowWidth > 600) {
        this.setState({
          isSmall: false
        })
        return;
      } else {
        this.setSize();
        this.setState({
          isSmall: true
        })
      }
    })
    if (windowWidth <= 600) {
      this.setSize();
      this.setState({
        isSmall: true
      })
    } else {
      this.setState({
        isSmall: false
      })
    }

    if (this.props.source === 'landing') {
      this.setState({
        poster: LandingPoster,
        sourceMP4: 'https://humbly-marketing.web.app/static/media/landing-demo.fcd0cf11.mp4',
        sourceOGG: 'https://humbly-marketing.web.app/static/media/landing-demo.1a2c981d.ogg'
      })
    }
    if (this.props.source === 'advertise') {
      this.setState({
        poster: AdvertisePoster,
        sourceMP4: 'https://humbly-marketing.web.app/static/media/ad-demo.3b66e422.mp4',
        sourceOGG: 'https://humbly-marketing.web.app/static/media/ad-demo.40dac9aa.ogg'
      })
    }
  }

  render() {
    const { sourceMP4, sourceOGG, poster } = this.state;
    return (
      <div id="device" className="device" ref={this.deviceRef}>
        <div className="device-contents">
          <video id="video" autoPlay playsInline loop muted={true} poster={poster}>
            {!this.state.isSmall || this.props.showAnyway ?
              <>
                <source src={sourceMP4} type="video/mp4" />
                <source src={sourceOGG} type="video/ogg" />
              </>
            : ''
            }
          </video>
        </div>
      </div>
    )
  }
}

Device.propTypes = {
  source: PropTypes.string.isRequired
}

export default Device;
