import React from 'react';

import appStoreIcon from '../images/appStoreIcon.svg';

export default function AppStoreButton(props) {
  return (
    <a href="https://apps.apple.com/us/app/humbly-fm/id1521804753" className={`app-store-soon ${props.classes ? props.classes : ''}`}>
      <img src={appStoreIcon} alt="Apple App Store" />
    </a>
  )
}