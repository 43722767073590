import React from 'react';
import '../styles/signup.scss';
import '../styles/spinner.scss';

class Signup extends React.Component {
  constructor() {
    super();
    this.state = {
      isSubmitting: false,
      justFinished: false,
      disableSubmit: false
    }
    this.submitEmail = this.submitEmail.bind(this);
  }

  submitEmail = () => {
    const emailField = document.getElementById('signup-email');
    const email = emailField.value
    if (email.length === 0) return;
    this.setState({
      isSubmitting: true,
      disableSubmit: true
    });
    fetch(`https://us-central1-humbly-app-7410e.cloudfunctions.net/addToBetaList?email=${email}`, {
      method: 'GET', // or 'PUT'
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(res => {
      this.setState({
        isSubmitting: false,
        justFinished: true
      })
      setTimeout(() => {
        emailField.value = '';
        this.setState({
          justFinished: false,
          disableSubmit: false
        })
      }, (5000));
    }).catch(err => {
      this.setState({
        isSubmitting: false,
        disableSubmit: false
      })
      console.log(err);
    })
  }

  render() {
    const { isSubmitting, justFinished, disableSubmit } = this.state;

    function buttonText() {
      if (isSubmitting) return <div className="lds-roller signup-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>;
      if (!isSubmitting && justFinished) return <span className="signup-button-text">Thank you!</span>
      if (!isSubmitting) return <span className="signup-button-text">Sign up</span>
    }

    return (
      <div className="signup">
        <div>
          <h2>Stay informed</h2>
          <p>
            Sign up to stay informed about things related to Humbly. We will never sell your information, and only use it to occasionally update you on things.
        </p>
          <input id="signup-email" className="signup-email" placeholder="Email" />
          <button className="signup-button" onClick={this.submitEmail} disabled={disableSubmit ? true : false}>
            {buttonText()}
          </button>
        </div>
      </div>
    )
  }
};

export default Signup;